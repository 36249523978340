<template>
  <div class="input-align">
    <ui5-label
      v-if="!hideLabel"
      class="modal-input"
      :value-state="isValid ? '' : 'Error'"
      v-bind="labelRules"
      wrapping-type="Normal"
    >
      {{ label }}
    </ui5-label>
    <input
      class="modal-input"
      type="date"
      :min="min"
      :max="max"
      :value-state="isValid ? '' : 'Error'"
      v-bind="inputRules"
      pattern="\d{4}-\d{2}-\d{2}"
      style="width: 210px"
      @change="$emit('input', $event.target.value)"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import inputMixin from '../mixins/input-mixin';

const DateInput = Vue.component('DateInput', {
  mixins: [inputMixin],
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    min: {
      type: String,
      required: false,
      default: null,
    },
    max: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    inputRules() {
      return {
        id: this.name,
        name: this.name,
        value: this.value,
        placeholder: this.placeholder,
        required: this.required,
        readonly: this.readonly,
      };
    },
    labelRules() {
      return {
        for: this.name,
        required: this.required,
      };
    },
    isValid() {
      return (
        !this.required ||
        this.readonly ||
        this.dependingElementsMatch === false ||
        (this.required &&
          this.isValueValid(this.value) &&
          this.value.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/))
      );
    },
  },
});

export default DateInput;
</script>

<style scoped>
.modal-input {
  display: block;
}
</style>
