<template>
  <div class="input-align">
    <ui5-label
      class="modal-input"
      :value-state="isValid ? '' : 'Error'"
      v-bind="labelRules"
      wrapping-type="Normal"
    >
      {{ label }}
    </ui5-label>
    <ui5-select
      class="modal-input modal-select"
      :value-state="isValid ? '' : 'Error'"
      v-bind="inputRules"
      @change="onChange"
    >
      <template v-if="readonly">
        <ui5-option class="modal-select-option">{{ value }}</ui5-option>
        <ui5-option
          v-for="(option, index) in options.filter((o) => o !== value)"
          :key="index"
          :value="option"
          class="modal-select-option"
        >
          {{ option }}
        </ui5-option>
      </template>
      <template v-else>
        <ui5-option
          v-for="(option, index) in orderedOptions"
          :key="index"
          :value="option"
          class="modal-select-option"
        >
          {{ option }}
        </ui5-option>
      </template>
    </ui5-select>
  </div>
</template>

<script>
import Vue from 'vue';
import inputMixin from '../mixins/input-mixin';

const SelectList = Vue.component('SelectList', {
  mixins: [inputMixin],
  props: {
    multi: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    orderedOptions: [],
  }),
  computed: {
    inputRules() {
      return {
        id: this.name,
        name: this.name,
        required: this.required,
        disabled: this.readonly,
      };
    },
    labelRules() {
      return {
        for: this.name,
        required: this.required,
      };
    },
  },
  mounted() {
    if (!this.value && !this.readonly) {
      this.orderedOptions = this.options;
      this.$emit('input', this.options[0]);
    } else {
      this.orderedOptions = [
        this.value,
        ...this.options.filter((o) => o !== this.value),
      ];
    }
  },
  methods: {
    onChange(event) {
      this.$emit('input', event.target.selectedOption?.value);
    },
  },
});

export default SelectList;
</script>

<style scoped>
.modal-input {
  display: block;
}
</style>
